import React, { Suspense, useContext, useEffect } from 'react';
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UnorderedListOutlined,
  InboxOutlined,
  AuditOutlined,
  ApiOutlined,
  UserOutlined,
  TeamOutlined,
  LogoutOutlined,
  FileTextOutlined,
  GroupOutlined,
  ThunderboltOutlined,
  ShoppingCartOutlined,
  BellOutlined,
  PieChartOutlined,
  FlagOutlined,
  DatabaseOutlined,
  BookOutlined,
  HistoryOutlined,
  LockOutlined,
  SolutionOutlined,
  DashboardFilled,
  PartitionOutlined,
  RollbackOutlined,
  UserSwitchOutlined,
  UsergroupDeleteOutlined,
  DingtalkOutlined,
  PushpinOutlined,
  BgColorsOutlined,
  BranchesOutlined,
  NodeExpandOutlined
} from '@ant-design/icons';
import UA from 'antd/locale/uk_UA';
import GB from 'antd/locale/en_US';
import RU from 'antd/locale/ru_RU';
import {
  Layout,
  Menu,
  Dropdown,
  Badge,
  Drawer,
  Switch,
  ConfigProvider,
  theme, App,
} from 'antd';
import { useState } from 'react';
import styles from './AppLayout.module.css';
import {Link, Outlet, redirect, useLocation, useNavigate, useSearchParams} from 'react-router-dom';
import { DomainIcon } from '../../../resources/icons/DomainIcon';
import { CloudFlareIcon } from '../../../resources/icons/CloudFlareIcon';
import { ServerIcon } from '../../../resources/icons/ServerIcon';
import { RegwayIcon } from '../../../resources/icons/RegwayIcon';
import { NotificationItem } from '../../../shared/Notification/NotificationItem';
import api from '../../../api/api';
import { socket } from '../../../api/socket';
import { useDidUpdateEffect } from '../../../hooks/useDidUpdateEffect';
import { NightIcon } from '../../../resources/icons/NightIcon';
import { DayIcon } from '../../../resources/icons/DaytIcon';
import { ThemeContext } from '../../../providers/ThemeProvider';
import { ChangeProfileModal } from './ChangeProfile/ChangeProfileModal';
import { useDispatch, useSelector } from 'react-redux';
import { resetState } from '../../../store/sharedSlices/defaultPageSlice';
import { useDeleteSessionMutation, useGetSessionQuery } from './sessionsApi';
import { useHandleLogout } from '../../../hooks/useHandleLogout';
import { resetStateLeads } from '../../../pages/Leads/leadsSlice';
import { resetStateReports } from '../../../pages/Report/reportsSlice';
import { resetStateDashboard } from '../../../pages/Dashboard/dashboardSlice';
import { Loader } from '../../../shared/Loader/Loader';
import { themes } from '../../../pages/Report/fakeReports/themes';
import { hasPermissions } from '../../../hooks/usePermissionsAllowed';
import { PERMISSIONS } from '../../../utils/permissions';
import { useIntl } from 'react-intl'
import {LangMainSelect} from "../../../shared/LangSelect/LangSelect";
import {LOCALES} from "../../../i18n/locales";
import {setUser} from "../../../store/sharedSlices/globalSlice";
import helmet, {Helmet} from "react-helmet";
const { Header, Content, Sider } = Layout;



const Logo = ({ collapsed, logo }) => {
  if(logo === 'default') {
    logo = process.env.PUBLIC_URL + '/image/midas_white.png'; // sperm-leads!
  }
  if (!collapsed) {
    return <img src={logo} alt="" className={styles.icon} />;
  }
  return '';
};



const AppLayout = () => {
  const [menuItems, setMenuItems] = useState([]);
  const { isDarkTheme, setIsDarkTheme } = useContext(ThemeContext);
  const { defaultAlgorithm, darkAlgorithm } = theme;
  const { message } = App.useApp();
  const { pathname, search } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [crmSetting, setCrmSetting] = useState()
  const navigate = useNavigate();
  const { data, isFetching, isSuccess } = useGetSessionQuery(
    {},
    { pollingInterval: 15000 }
  );
  const { selectedThemeKey } = useSelector((state) => state.reports);
  const [deleteSession] = useDeleteSessionMutation();
  const [handleLogout] = useHandleLogout();
  const isLoginAsUse = localStorage.getItem('login_as');
  const { formatMessage: f } = useIntl();
  const { user } = useSelector((state) => state.globalSlice);
  const { crmSettings } = useSelector((state) => state.globalSlice);


  function getItem(label, key, icon, children, type) {
    if (typeof label === "object" && user?.type !== "buyer"){
      label = <>{label}
          <PushpinOutlined
              onClick={() => handlePinLinkMenu(key)}
              className={styles.pinItem}
          />
      </>
    }
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }
  useEffect(() => {

    if (!data?.isActive && !isFetching && isSuccess) {
      handleLogout();
    }
  }, [data, isFetching]);


  const backToOriginalUser = () => {
    api
    .post(`/auth/back-to-user`, {
      device: navigator.userAgent,
    })
    .then((res) => {
      handleLogout();
      localStorage.removeItem('login_as');
      localStorage.setItem('access_token', res.data.access_token);
      localStorage.setItem('entry_type',  res.data.user_type);
      navigate('/');
      window.location.reload();
    })
  }
  const checkDrawerToRedirect = () => {
    if(searchParams?.has('drawer')) {
      const urlParse = JSON.parse(searchParams.get('drawer')) ? JSON.parse(searchParams.get('drawer')) : null
      const keys = Object.keys(urlParse);
      const lastKey = keys[keys.length - 1];
      const lastElement = urlParse[lastKey];
      return navigate(`/${lastElement.type}/${lastElement.id}`);
    }
  }

  useEffect(() => {
    api.get('crm-setting').then(({ data }) => {
      setCrmSetting(data)
      if(data.title) {
        document.title = data.title;
      }
      // Динамічно змінюємо favicon
      const link = document.querySelector("link[rel~='icon']");
      if (!link) {
        const newLink = document.createElement('link');
        newLink.rel = 'icon';
        newLink.href = (data.faviconUrl === undefined || data.faviconUrl === 'default')
            ? process.env.PUBLIC_URL + '/image/favicon64.png'
            : data.faviconUrl;
        document.head.appendChild(newLink);
      } else {
        link.href = (data.faviconUrl === undefined || data.faviconUrl === 'default')
            ? process.env.PUBLIC_URL + '/image/favicon64.png'
            : data.faviconUrl;
      }

      // Динамічно змінюємо опис
      let metaDescription = document.querySelector("meta[name='description']");
      if (!metaDescription) {
        metaDescription = document.createElement('meta');
        metaDescription.name = 'description';
        document.head.appendChild(metaDescription);
      }
      metaDescription.content = data.description;
    })
    if (!search.length) {
      dispatch(resetState());
      dispatch(resetStateLeads());
      dispatch(resetStateReports());
      dispatch(resetStateDashboard());
    }
    checkDrawerToRedirect()
  }, [pathname]);
  const allMenuItems = [
    ((hasPermissions(user, PERMISSIONS.LEADS_READ) &&
            hasPermissions(user, PERMISSIONS.BUYERS_READ)) ||
        user?.type === 'buyer') &&
    getItem(
        f({ id: 'dashboard_menu' }),
        'gasd',
        null,
        [
          getItem(
              <Link to="/dashboard">{f({ id: 'dashboard_menu' })}</Link>,
              '/dashboard',
              <DashboardFilled />,
          ),
        ],
        'group'
    ),
    (hasPermissions(user, PERMISSIONS.LEADS_READ) ||
        hasPermissions(user, PERMISSIONS.BOXES_READ) ||
        hasPermissions(user, PERMISSIONS.ORDERS_READ) ||
        hasPermissions(user, PERMISSIONS.CUSTOMERS_READ) ||
        hasPermissions(user, PERMISSIONS.BUYERS_READ) ||
        hasPermissions(user, PERMISSIONS.CALL_STATUSES_READ) ||
        hasPermissions(user, PERMISSIONS.KPI_EDIT) ||
        user?.type === 'buyer') &&
    getItem(
        f({ id: 'main_menu' }),
        'g1',
        null,
        [
          (hasPermissions(user, PERMISSIONS.LEADS_READ) ||
              user?.type === 'buyer') &&
          getItem(
              <Link className={styles.linkWrapper} to="/leads">{f({ id: 'lead_menu' })}
              </Link>,
              '/leads',
              <UnorderedListOutlined />,
          ),
          hasPermissions(user, PERMISSIONS.ORDERS_READ) &&
          getItem(
              <Link to="/caps">{f({id: 'caps_menu'})}</Link>,
              '/caps',
              <DatabaseOutlined />
          ),
          hasPermissions(user, PERMISSIONS.BOXES_READ) &&
          getItem(
              <Link className={styles.linkWrapper} to="/boxes">{f({ id: 'boxes_menu' })}
              </Link>,
              '/boxes',
              <InboxOutlined />
          ),
          hasPermissions(user, PERMISSIONS.ORDERS_READ) &&
          getItem(
              <Link to="/orders">{f({ id: 'orders_menu' })}</Link>,
              '/orders',
              <AuditOutlined />
          ),
          hasPermissions(user, PERMISSIONS.CUSTOMERS_READ) &&
          getItem(
              <Link to="/customers">{f({ id: crmSettings.advertiser })}</Link>,
              '/customers',
              <UserOutlined />
          ),
          hasPermissions(user, PERMISSIONS.BUYERS_READ) &&
          getItem(
              <Link to="/webmasters">{f({ id: crmSettings.affiliate })}</Link>,
              'webmasters',
              <UserSwitchOutlined />
          ),
        ],
        'group'
    ),
    (hasPermissions(user, PERMISSIONS.LEADS_READ) ||
        user?.isFakeReportAvailable ||
        user?.isAdmin) &&
    getItem(
        f({ id: crmSettings.reports }),
        'g4',
        null,
        [
          hasPermissions(user, PERMISSIONS.LEADS_READ) &&
          getItem(
              <Link to="/report">{f({ id: 'general_reports_menu' })}</Link>,
              '/report',
              <PieChartOutlined style={{ fontSize: 14 }} />
          ),
          ((user?.isFakeReportAvailable &&
                  hasPermissions(user, PERMISSIONS.LEADS_READ)) ||
              user?.isAdmin) &&
          getItem(
              <Link to="/designer-reports">{f({ id: 'report_builder_menu' })}</Link>,
              '/designer-reports',
              <PieChartOutlined style={{ fontSize: 14 }} />
          ),
          ((user?.isFakeReportAvailable &&
                  hasPermissions(user, PERMISSIONS.LEADS_READ)) ||
              user?.isAdmin) &&
          getItem(
              <Link to="/design-reports">Дизайнер</Link>,
              '/design-reports',
              <PieChartOutlined style={{ fontSize: 14 }} />
          ),
          hasPermissions(user, PERMISSIONS.KPI_EDIT) &&
          getItem(
              <Link to="/kpi-report">{f({ id: 'kpi_report' })}</Link>,
              '/kpi-report',
              <PieChartOutlined style={{ fontSize: 14 }} />
          ),
        ],
        'group'
    ),
    (hasPermissions(user, PERMISSIONS.CALL_STATUSES_READ) ||
        hasPermissions(user, PERMISSIONS.CALL_STATUSES_GROUP_READ) ||
        hasPermissions(user, PERMISSIONS.KPI_EDIT)) &&
    getItem(f({ id: 'call_statuses_menu' }), 'g678', null, [
      hasPermissions(user, PERMISSIONS.CALL_STATUSES_READ) &&
      getItem(
          <Link to="/call-status-categories">{f({ id: 'call_statuses_menu' })}</Link>,
          '/call-status-categories',
          <GroupOutlined />
      ),
      hasPermissions(user, PERMISSIONS.CALL_STATUSES_GROUP_READ) &&
      getItem(
          <Link to="/call-status-groups">{f({ id: 'group_call_statuses_menu' })}</Link>,
          '/call-status-groups',
          <GroupOutlined />
      ),
      hasPermissions(user, PERMISSIONS.CALL_STATUSES_READ) &&
      getItem(
          <Link to="/call-status-answer">{f({id: 'is_answer'})}</Link>,
          '/call-status-answer',
          <GroupOutlined />
      ),
      hasPermissions(user, PERMISSIONS.KPI_EDIT) &&
      getItem(
          <Link to="/kpi-groups">KPI</Link>,
          '/kpi-groups',
          <GroupOutlined />
      ),
    ]),
    (hasPermissions(user, PERMISSIONS.USERS_READ) ||
        hasPermissions(user, PERMISSIONS.PERMISSIONS_READ) ||
        hasPermissions(user, PERMISSIONS.INTEGRATIONS_READ) ||
        hasPermissions(user, PERMISSIONS.TEAMS_READ)) &&
    getItem(f({ id: 'management_menu' }), 'g2', null, [
      hasPermissions(user, PERMISSIONS.PERMISSIONS_READ) &&
      getItem(
          <Link to="/permissions">{f({ id: 'permissions_menu' })}</Link>,
          '/permissions',
          <LockOutlined />
      ),
      hasPermissions(user, PERMISSIONS.PERMISSIONS_READ) &&
      getItem(
          <Link to="/buyer-permissions">{f({ id: 'buyer_permissions_menu' })}</Link>,
          '/buyerpermissions',
          <LockOutlined />
      ),
      hasPermissions(user, PERMISSIONS.USERS_READ) &&
      getItem(
          <Link to="/users">{f({ id: 'users_menu' })}</Link>,
          '/users',
          <UserOutlined />
      ),
      hasPermissions(user, PERMISSIONS.TEAMS_READ) &&
      getItem(
          <Link to="/teams">{f({ id: 'teams_menu' })}</Link>,
          '/teams',
          <TeamOutlined />
      ),
    ]),
    (hasPermissions(user, PERMISSIONS.LOGS_READ) ||
        hasPermissions(user, PERMISSIONS.BOXES_READ) ||
        hasPermissions(user, PERMISSIONS.COUNTRY_BOXES_READ) ||
        hasPermissions(user, PERMISSIONS.BLACKLISTS_READ) ||
        (user?.permissions?.isCanInject || user?.isAdmin || user?.isCanInject) ||
        hasPermissions(user, PERMISSIONS.INTEGRATIONS_READ)) &&
    getItem(f({ id: 'administrations_menu' }), 'g3', null, [
      hasPermissions(user, PERMISSIONS.LOGS_READ) &&
      getItem(
          <Link to="/logs">{f({ id: 'lead_logs_menu' })}</Link>,
          '/logs',
          <FileTextOutlined />
      ),
      hasPermissions(user, PERMISSIONS.LOGS_READ) &&
      getItem(
          <Link to="/entity-logs">{f({ id: 'entity_logs_menu' })}</Link>,
          '/entity-logs',
          <DatabaseOutlined />
      ),
      hasPermissions(user, PERMISSIONS.INTEGRATIONS_READ) &&
      getItem(
          <Link to="/integrations">{f({ id: 'integrations_menu' })}</Link>,
          '/integrations',
          <ApiOutlined />
      ),
      hasPermissions(user, PERMISSIONS.INTEGRATIONS_READ) &&
      getItem(
          <Link to="/template-integrations">{f({ id: 'integration_template' })}</Link>,
          '/template-integrations',
          <BranchesOutlined />
      ),
      hasPermissions(user, PERMISSIONS.BRANDS_READ) &&
      getItem(
          <Link to="/brands">{f({ id: 'brands_menu' })}</Link>,
          '/brands',
          <PartitionOutlined />
      ),
      hasPermissions(user, PERMISSIONS.BLACKLISTS_READ) &&
      getItem(
          <Link to="/blacklists">{f({ id: 'blacklists_menu' })}</Link>,
          '/blacklists',
          <UsergroupDeleteOutlined />
      ),
      hasPermissions(user, PERMISSIONS.COUNTRY_BOXES_READ) &&
      getItem(
          <Link to="/country-box">{f({ id: 'country_box_menu' })}</Link>,
          '/country-box',
          <FlagOutlined />
      ),
      (user?.isCanInject || user?.isAdmin) &&
      getItem(
          <Link to="/inject-form">{f({ id: 'inject_form_menu' })}</Link>,
          '/inject-form',
          <ThunderboltOutlined />
      ),
      user?.isAdmin && getItem(
          <Link to="/crm-setting">{f({id: 'settings'})}</Link>,
          '/crm-setting',
          <BgColorsOutlined />
      ),
    ]),
    hasPermissions(user, PERMISSIONS.DOMAINS_READ) &&
    getItem(f({ id: 'domain_purchases_menu' }), 'g123', null, [
      getItem(
          <Link to="/domains">{f({ id: 'domains_menu' })}</Link>,
          'domains',
          <DomainIcon style={{ fontSize: 14 }} />
      ),
      getItem(
          <Link to="/buy">{f({ id: 'purchases_menu' })}</Link>,
          'buy',
          <ShoppingCartOutlined style={{ fontSize: 14 }} />
      ),
      getItem(
          <Link to="/cloudflare">{f({ id: 'cloudflare_menu' })}</Link>,
          'cloudflare',
          <CloudFlareIcon />
      ),
      getItem(
          <Link to="/servers">{f({ id: 'servers_menu' })}</Link>,
          'servers',
          <ServerIcon />
      ),
      getItem(
          <Link to="/regway">{f({ id: 'regway_menu' })}</Link>,
          'regway',
          <RegwayIcon />
      ),
      getItem(
          <Link to="/namecheap">{f({ id: 'namecheap_menu' })}</Link>,
          'namecheap',
          <DingtalkOutlined />
      ),
    ]),
    hasPermissions(user, PERMISSIONS.OFFERS_READ) &&
    getItem(f({ id: 'offers_menu' }), 'g5', null, [
      hasPermissions(user, PERMISSIONS.OFFERS_READ) &&
      getItem(
          <Link to="/groups-offer">{f({ id: 'group_offers_menu' })}</Link>,
          'groups-offer',
          <DatabaseOutlined />
      ),
      hasPermissions(user, PERMISSIONS.OFFERS_READ) &&
      getItem(
          <Link to="/offers">{f({ id: 'offers_menu' })}</Link>,
          'offers',
          <BookOutlined />
      ),
      hasPermissions(user, PERMISSIONS.OFFERS_READ) &&
      getItem(
          <Link to="/directions">{f({id: 'direction'})}</Link>,
          'directions',
          <NodeExpandOutlined />
      ),
    ]),
  ];

  useEffect(() => {
    if (user) {
      if (user.type === "buyer"){
        setMenuItems(allMenuItems);
        return
      }
      let links =  user.pinnedLinks ? JSON.parse(user.pinnedLinks): [];
      if (links.length >= 1){
        const items = allMenuItems.map(i => i.children).flat();
        const foundChildren = items.filter(i => links.includes(i?.key));
        setMenuItems([getItem(
            f({ id: 'pinned_menu' }),
            'plleeos',
            null,
            foundChildren,
            'group'
        ), ...allMenuItems]);
      } else {
        setMenuItems(allMenuItems)
      }
    }
  }, [user]);

  // const { pathname } = useLocation();
  const [collapsed, setCollapsed] = useState(false);
  let label;

  const [title, setTitle] = useState(label);
  const [url, setUrl] = useState(label);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const dispatch = useDispatch();

  const [isModalOpenProfile, setIsModalOpenProfile] = useState(false);
  useEffect(() => {

   if(user && ( (!user.otpEnabled && user.isAdmin) || (user.isExportAvailable && !user.otpEnabled)) ) {
      message.success(f({id: 'please_add_2FA'}));
      setIsModalOpenProfile(true)
    }
    if (menuItems.length) {
      menuItems.forEach((i) => {
        if (i) {
          const item = i.children.find((ii) => pathname.includes(ii?.key));
          if (item?.label) {
            label = item.label;
            setUrl(item?.key);
          }
        }
      });
    }
    if (label) {
      setTitle(label.props.children);
    }
    if (pathname === '/' && user?.role) {
      if (
        ['SUPPORT_SENIOR', 'SUPPORT_JUNIOR', 'SUPPORT_ADMIN'].includes(
          user?.role
        )
      ) {
        navigate('/domains');
        setTitle(f({id: 'domains_menu'}));
      } else if (['LEAD_MANAGER'].includes(user?.role)) {
        navigate('/leads');
        setTitle(f({id: 'lead_menu'}));
      } else {
        navigate('/dashboard');
        setTitle(f({id: 'dashboard_menu'}));
      }
    }
  }, [pathname, menuItems, user]);

  useDidUpdateEffect(() => {
    if (user) {
      socket.on('user-logout', (res) => {
        if (user.id === res?.userId && res.type === user.type) {
          handleLogout();
        }
      });
    }
    fetchNotifications();
    socket.on('notification-created', (res) => {
      if (res.find((n) => n.user_id === user.id)) {
        setNotifications(res);
      }
    });
  }, [user]);

  const fetchNotifications = () => {
    api.get('/notification').then((res) => setNotifications(res.data));
  };

  const handleModalProfileCancel = () => {
    if(user && !user.otpEnabled && user.isAdmin) {
      message.success(f({id: 'please_add_2FA'}));
      return
    }
    setIsModalOpenProfile(false);
  };
  const onResendUpdateSuccess = () => {
    if(user && !user.otpEnabled && user.isAdmin) return
    setIsModalOpenProfile(false);
  };

  const handlePinLinkMenu = (key) => {
    if (user?.type === "buyer") return;
      let updatedPinnedListMenu = [...JSON.parse(user.pinnedLinks)];
     if (!updatedPinnedListMenu.includes(key)) {
       updatedPinnedListMenu.push(key);
     } else {
       updatedPinnedListMenu = updatedPinnedListMenu.filter(item => item !== key);
     }

     api.put(`/user/update-pined-lists/${user.id}`, {
       isActive: true,
       pinnedLinks: JSON.stringify(updatedPinnedListMenu)
     }).then((res) => {
       dispatch(setUser(res.data));
       const newLinks = JSON.parse(res.data.pinnedLinks);
       const items = allMenuItems.map(i => i?.children).flat();
       const foundChildren = items.filter(i => newLinks.includes(i.key));
       setMenuItems([getItem(
           f({id: 'pinned_menu'}),
           'plleeos',
           null,
           foundChildren,
           'group'
       ), ...allMenuItems]);
     }).catch((res) => {
       // console.error(res);
     });

    return null;
  };




  const profileItems = [
    {
      key: '1',
      label: (
        <div
          className={styles.profileItem}
          onClick={() => setIsModalOpenProfile(true)}
        >
          <SolutionOutlined />
          <div className={styles.profileItemText}>{f({id: 'profile'})}</div>
        </div>
      ),
    },
    {
      key: '2',
      label: (
        <div className={styles.profileItem} onClick={handleLogout}>
          <LogoutOutlined />
          <div className={styles.profileItemText}>{f({id: 'logout'})}</div>
        </div>
      ),
    },
  ];
  return (
    <ConfigProvider
      locale={LOCALES[localStorage.getItem('locale') || 'UA'].lcConfig}
      theme={{
        algorithm: isDarkTheme ? darkAlgorithm : defaultAlgorithm,
        token: {
          colorBgBase:
            ['/designer-reports', '/design-reports'].includes(pathname)
              ? themes[selectedThemeKey]?.token?.colorBgBase
              : isDarkTheme && '#323743',
          colorBgContainer: isDarkTheme ? '#272b34' : '#fafafa',
          colorPrimary: isDarkTheme ? '#21c1d6' : '#1677ff',
        },
        components: {
          Layout: {
            colorBgContainer: isDarkTheme && '#1d2126',
          },
          Menu: {
            colorBgContainer: isDarkTheme && '#1d2126',
            itemSelectedBg: isDarkTheme && '#21c1d6',
            itemActiveBg: isDarkTheme && '#21c1d6',
            itemSelectedColor: isDarkTheme && '#ffffff',
          },
          Table: {
            colorBgContainer: isDarkTheme ? '#272b34' : '#fafafa',
          },
        },
      }}
    >
      <Suspense fallback={<Loader isFullScreen />}>
        <Layout className={styles.layout}>
          <Sider
            collapsible
            collapsed={collapsed}
            onCollapse={(value) => setCollapsed(value)}
            trigger={null}
            theme={isDarkTheme ? 'light' : 'dark'}
          >
            <div className={styles.iconContainer}>
              <div>
                <Logo collapsed={collapsed} logo={crmSetting?.logoUrl}/>
              </div>
              {React.createElement(
                collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
                {
                  className: `trigger ${styles.collapseButton}`,
                  onClick: () => setCollapsed(!collapsed),
                }
              )}
            </div>
            <Menu
              theme={isDarkTheme ? 'light' : 'dark'}
              selectedKeys={[url]}
              onClick={({ key }) => {
                menuItems.forEach((i) => {
                  if (i) {
                    const label = i.children.find(
                      (ii) => ii?.key === key
                    )?.label;
                    if (label && pathname === i.children.key) setTitle(label.props.children);
                  }
                });
              }}
              defaultSelectedKeys={[url]}
              mode="inline"
              items={menuItems}
            />
          </Sider>
          <Layout id="main-d" className={styles.layout}>
            <Header
              className={styles.header}
              style={{
                background: isDarkTheme
                  ? 'linear-gradient(180deg, #1d2126 0%, #323743 100%)'
                  : 'white',
                color: !isDarkTheme && 'black',
              }}
            >
              <div className={styles.title}>{title}</div>
              <div className="flex items-center">
                <div className="mr-8">
                  <LangMainSelect
                      onChange={(e) =>{
                        localStorage.setItem('locale', e );
                        window.location.reload();
                      } }
                  />
                </div>
                  <div className="mr-8">
                    <Switch
                        defaultChecked={isDarkTheme}
                        checkedChildren={<NightIcon/>}
                        unCheckedChildren={<DayIcon/>}
                        onChange={(e) => {
                          setIsDarkTheme(e);
                          localStorage.setItem('isDarkTheme', e);
                        }}
                    />
                  </div>
                  {(user && pathname !== '/designer-reports') && (
                      <div className="mr-8 text-lg">
                        ID: {user.id} | {user.nickname}
                      </div>
                  )}
                  <div className={styles.profile}>
                    <Link
                        style={{color: isDarkTheme ? '#fff' : '#000'}}
                        to="/tasks"
                    >
                      <HistoryOutlined/>
                    </Link>
                  </div>
                  <Dropdown
                      menu={{
                        items: profileItems,
                      }}
                      placement="bottom"
                      trigger={['click']}
                  >
                    <div className={styles.profile}>
                      <UserOutlined/>
                    </div>
                  </Dropdown>
                  <div>
                    <Badge
                        style={{color: '#fff'}}
                        className={styles.profile}
                        count={notifications.length}
                    >
                      <BellOutlined onClick={() => setIsDrawerOpen(true)}/>
                    </Badge>
                    <Drawer
                        title={f({id: 'notifications'})}
                        placement="right"
                        open={isDrawerOpen}
                        onClose={() => setIsDrawerOpen(false)}
                    >
                      {!!notifications.length || f({id: 'not_unread_notifications'})}
                      {notifications.map((n) => {
                        return (
                            <NotificationItem
                                notification={n}
                                refetch={fetchNotifications}
                                close={() => setIsDrawerOpen(false)}
                            />
                        );
                      })}
                    </Drawer>
                  </div>
                </div>
            </Header>
            <ChangeProfileModal
              isModalOpen={isModalOpenProfile}
              handleCancel={handleModalProfileCancel}
              onSuccess={onResendUpdateSuccess}
            />
            <Content className={styles.contentContainer}>
              <div className={styles.content}>
                <Outlet />
              </div>
              {isLoginAsUse && (
                <div className={styles.line} onClick={backToOriginalUser}>
                  <div className={styles.lineInner}>
                    <span className={styles.lineInnerText}>{f({id: 'back_to_account'})}</span>  <RollbackOutlined />
                  </div>
                </div>
              )}
            </Content>
          </Layout>
        </Layout>
      </Suspense>
    </ConfigProvider>
  );
};

export default AppLayout;
